
import "./header.css";





const Header = () => {
    return (
        <header className='header'>

            <div className="top-header">
                <div className="top-header-menu">
                    <i className="bi bi-list"></i>
                </div>
                <div className="top-header-logo">
                    <i className="bi bi-basket2"></i>
                    Faddo Shop
                </div>
                <div className="top-header-text">أهلا وسهلا بكم</div>
                <div className="top-header-phone">
                    0555253065
                    <i className="bi bi-telephone"></i>
                </div>
            </div>

            <div className="middle-header">
                <div className="middle-header-search-box">
                    <input type="search" placeholder="ماذا ترید؟" />
                    <button className="search-box-btn">ابحث</button>
                </div>
                
                <div to="/cart" className="middle-header-shopping-cart">
                    سله التسوق
                    <i className="bi bi-cart3"></i>
                    <span className="cart-notification">1</span>
                </div>
            </div>


        </header>
    )
}

export default Header

